.hidden {
  display: none !important;
}

.display-contents {
  display: contents;
}

.overflow-hidden {
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
}
