.portfolio-item {
  height: 100%;
  min-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &.has-background {
    .portfolio {
      &-image, &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .badge-container {
    .badge {
      background-color: rgba($dark, .5);
    }
  }

  .description {
    background-color: rgba($dark, 0.6);
    transition: $transition-base;

    &-container {
      bottom: 8px;
      left: 8px;
      width: calc(100% - 16px);
    }

    .title {
      font-weight: bold;
    }
  }

  * {
    cursor: pointer;
  }

  .hover-overlay {
    bottom: 8px;
    left: 16px;
    width: calc(100% - 16px);
    height: 32px;
    border-radius: 4;
    transition: $transition-base;
  }

  &:hover {
    .description {
      background: rgba($dark, 0);
    }

    .badge-container {
      .badge {
        background-color: rgba($dark, 0);
      }
    }

    .hover-overlay {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
      background-color: rgba($dark, 0.6);
    }
  }
}
